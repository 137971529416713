/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { transparentize, rem, linearGradient } from 'polished';
import BackgroundImage from 'gatsby-background-image';
import { linkResolver } from '@theprepgroup/gatsby-source-prismic-graphql';

import { RichText } from 'prismic-reactjs';
import LinkFragment from '../utils/LinkFragment';
import useLangRedirect from '../hooks/useLangRedirect';

import Button from '../components/Button';
import SEO from '../components/SEO';
import Image from '../components/Image';
import Layout from '../components/Layout';
import ProjectItem from '../components/ProjectItem';
import Row from '../components/Row';
import Col from '../components/Col';
import HeroCarousel from '../components/HeroCarousel';

import { breakpoint } from '../theme';

const IntroSection = styled.div`
  padding-top: ${rem(50)};
  padding-bottom: ${rem(50)};

  ${breakpoint('large')} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`;

const IntroContent = styled(Col)`
  text-align: center;
  width: 100%;
  max-width: ${rem(500)};
  margin: 0 auto;

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(570)};
  }
`;

const IntroImage = styled(Image)`
  position: relative;
  text-align: center;
  width: 100%;
  margin: 0 auto;

  &::before {
    content: '';
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    ${(props) => {
      const from = transparentize(0, props.theme.palette.background.body);
      const mid = transparentize(0.2, props.theme.palette.background.body);
      const to = transparentize(1, props.theme.palette.background.body);
      return linearGradient({
        colorStops: [`${from} 4%`, `${mid} 40%`, `${to} 100%`],
        toDirection: 'to bottom',
        fallback: 'transparent',
      });
    }}
  }
`;

const ProjectsSection = styled(Row)`
  padding-top: ${rem(16)};
  padding-bottom: ${rem(30)};
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: auto;

  ${breakpoint('large')} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`;

const ProjectCol = styled(Col)`
  width: 100%;
  margin-bottom: ${rem(42)};

  ${breakpoint('large')} {
    width: 50%;
  }
`;

const ProjectsContent = styled(Col)`
  width: 100%;
  margin-bottom: 1rem;

  ${breakpoint('large')} {
    width: 50%;
  }
`;

const ProjectsContentInner = styled.div`
  width: 100%;
  max-width: ${rem(500)};
  margin: 0 auto;

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(620)};
  }
`;

const TestimonialSection = styled(BackgroundImage)`
  padding-top: ${rem(10)};
  padding-bottom: ${rem(10)};
  position: relative;
  width: 100%;
  background-image: url(${(props) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-color: ${(props) => props.theme.palette.background.body};
  text-align: center;

  ${breakpoint('mediumlarge')} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(40)};
  }

  ${breakpoint('large')} {
    padding-top: ${rem(80)};
    padding-bottom: ${rem(40)};
  }
`;

const TestimonialName = styled.h6`
  margin: 0 0 ${rem(16)} 0;
  font-size: ${rem(14)};
  text-transform: uppercase;
  color: ${(props) => props.theme.palette.colors.primary};
  letter-spacing: 0.02em;
  font-family: ${(props) => props.theme.typography.fonts.primary};
  font-weight: ${(props) => props.theme.typography.weights.primary.bold};

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(15)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(16)};
  }
`;

const TestimonialContent = styled.blockquote`
  max-width: ${rem(630)};
`;

const SectionFooter = styled(Col)`
  text-align: center;
  width: 100%;
  margin-top: ${rem(20)};

  ${breakpoint('mediumlarge')} {
    margin-top: ${rem(25)};
  }

  ${breakpoint('large')} {
    margin-top: ${rem(30)};
  }
`;

const ClientSection = styled.div`
  display: block;
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
  width: 100%;
`;

const ClientItem = styled.div`
  display: inline-block;
  vertical-align: middle;
  width: ${rem(80)};
  margin-right: 1rem;
`;

const StyledContent = styled.div`
  h4,
  h5,
  h6 {
    margin: 0 0 ${rem(16)} 0;
    font-size: ${rem(14)};
    text-transform: uppercase;
    color: ${(props) => props.theme.palette.colors.primary};
    letter-spacing: 0.07em;

    ${breakpoint('mediumlarge')} {
      font-size: ${rem(15)};
    }

    ${breakpoint('large')} {
      font-size: ${rem(16)};
    }
  }

  p {
    color: ${(props) => props.theme.palette.colors.greyLight};
  }
`;

function Home({ data, pageContext }) {
  useLangRedirect(pageContext);

  const {
    prismic: {
      allHomepages: {
        edges: [{ node: page } = {}],
      },
      allSettingss: {
        edges: [{ node: settings } = {}],
      },
      allProjects: projects,
      allClients: clients,
    },
    file: testimonialBackground,
  } = data;

  if (!page) {
    return null;
  }

  const featuredTestimonial = page?.testimonials?.length
    ? page.testimonials[Math.floor(Math.random() * page.testimonials.length)]
        .testimonial
    : null;

  const withProjects = false;

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length && (
        <HeroCarousel items={page.hero_items} />
      )}

      {page.content && page.content.length && RichText.asText(page.content) && (
        <IntroSection>
          <Row>
            <IntroContent>
              <StyledContent>
                <RichText render={page.content} linkResolver={linkResolver} />
              </StyledContent>
            </IntroContent>
          </Row>
          {page.intro_image && (
            <IntroImage
              fluid={page.intro_imageSharp?.childImageSharp?.fluid}
              fallback={page.intro_image}
            />
          )}
        </IntroSection>
      )}

      {withProjects && (
        <ProjectsSection>
          <ProjectsContent>
            <ProjectsContentInner>
              <StyledContent>
                <RichText
                  render={page.projects_content}
                  linkResolver={linkResolver}
                />
              </StyledContent>
              {clients && (
                <ClientSection>
                  {clients.edges.map(({ node: client }) => (
                    <ClientItem key={client._meta.id}>
                      {client.featured_image && (
                        <Image
                          fluid={
                            client.featured_imageSharp?.childImageSharp?.fluid
                          }
                          fallback={client.featured_image}
                        />
                      )}
                    </ClientItem>
                  ))}
                </ClientSection>
              )}
            </ProjectsContentInner>
          </ProjectsContent>

          {projects.edges.map(({ node: project }) => (
            <ProjectCol key={project._meta.id}>
              <ProjectItem data={project} />
            </ProjectCol>
          ))}

          <SectionFooter>
            {settings.page_for_projects && (
              <Button link={settings.page_for_projects} variant="hollow">
                Se fler projekt
              </Button>
            )}
          </SectionFooter>
        </ProjectsSection>
      )}

      {featuredTestimonial && (
        <TestimonialSection
          Tag="div"
          fluid={testimonialBackground?.childImageSharp?.fluid}
        >
          <Row>
            <TestimonialContent>
              <RichText render={featuredTestimonial.quote} />
              <TestimonialName>
                {RichText.asText(featuredTestimonial.title)}
              </TestimonialName>
            </TestimonialContent>
          </Row>
        </TestimonialSection>
      )}
    </Layout>
  );
}

Home.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

Home.fragments = [LinkFragment];

export const query = graphql`
  query($id: String!, $lang: String!) {
    file(relativePath: { eq: "warehouse-bg-dark.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600, quality: 75) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    prismic {
      allHomepages(id: $id) {
        edges {
          node {
            title
            seo_description
            seo_meta_title
            projects_content
            intro_image
            intro_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1600, maxHeight: 520, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_items {
              ... on PRISMIC_HomepageHero_itemsImage_item {
                type
                label
                primary {
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link {
                    ...LinkFragment
                  }
                  title
                  text
                  pre_title
                  link_label
                }
              }
              ... on PRISMIC_HomepageHero_itemsVideo_item {
                type
                label
                primary {
                  link {
                    ...LinkFragment
                  }
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(maxWidth: 1200, quality: 75) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                  link_label
                  pre_title
                  text
                  title
                  video {
                    ... on PRISMIC__FileLink {
                      _linkType
                      name
                      size
                      url
                    }
                  }
                }
              }
            }
            featured_image
            featured_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            content
            testimonials {
              testimonial {
                ... on PRISMIC_Testimonial {
                  title
                  quote
                  _meta {
                    uid
                    type
                  }
                }
              }
            }
          }
        }
      }
      allProjects(lang: $lang, sortBy: title_ASC, first: 5) {
        edges {
          node {
            featured_image
            featured_imageSharp {
              childImageSharp {
                fluid(maxWidth: 900, maxHeight: 580, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            _meta {
              id
              uid
              lang
              type
            }
            _linkType
          }
        }
        totalCount
      }
      allClients(lang: $lang, sortBy: title_ASC, first: 4) {
        edges {
          node {
            featured_image
            featured_imageSharp {
              childImageSharp {
                fluid(maxWidth: 200, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            _meta {
              id
              lang
              uid
              type
            }
            link {
              ...LinkFragment
            }
            _linkType
          }
        }
      }
      allSettingss(lang: $lang) {
        edges {
          node {
            page_for_producs {
              ...LinkFragment
            }
            page_for_projects {
              ...LinkFragment
            }
          }
        }
      }
    }
  }
`;

export default Home;
